
import React from 'react'
// import { Link } from 'gatsby'
// import PropTypes from "prop-types"
import Layout from '../components/layout'
import Footer from '../components/Footer'
import withLocation from "../components/withLocation"
import TransactionEditForm from "../components/TransactionEditForm"


const CreateTransaction = ({ search }) => {
  const { trhash,accessTok } = search
  

  return(
  <Layout>
    <header id="header">
    <div className="offerta-logo">
        <span className="icon fa-handshake-o fa-2x "></span>
    </div>

    </header>
    
    <TransactionEditForm trhash={trhash} accessTok={accessTok} />
    <Footer/>
  </Layout>
  )
}

export default withLocation(CreateTransaction)
