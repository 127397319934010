import React from 'react';
// import Link from 'gatsby';

class TransactionEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trname: '',
      price: '',
      sellerName: '',
      sellerEmail: '',
      acceptOfferta:false,
      trhash:this.props.trhash,
      isErrorTrName: false,
      isErrorPrice: false,
      isErrorSellerEmail: false,
      isErrorSellerName: false,
      isErrorAcceptOfferta: false,
      errorInForm: false,
      accessTok:this.props.accessTok,
      errorMessages:{
        trname : null,
        price : null,
        sellerEmail : null,
        sellerName: null,
        acceptOfferta : null,
      },
      commonErrorMessage: null
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCheckBox = this.handleCheckBox.bind(this);
    this.handleInputChangeTrName = this.handleInputChangeTrName.bind(this);
    this.handleInputChangeSellerEmail = this.handleInputChangeSellerEmail.bind(this);
    this.handleInputChangeSellerName = this.handleInputChangeSellerName.bind(this);
  }

  componentDidMount () {
    console.log('component TransactionEdit From mounted');
    let requestUrl = `${process.env.GATSBY_TRUSTA_API_URL}/get-transaction?trhash=${this.props.trhash}&accessTok=${this.props.accessTok}`
    fetch(requestUrl, {
      method: 'GET', 
      mode: 'cors', // no-cors, *cors, same-origin
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      return response.json();
    }).then((data) => {
      if (data.status === 'success'){
        this.setState({
          trname : data.data.trname, 
          price: data.data.price,
          sellerName: data.data.sellerName,
          sellerEmail: data.data.sellerEmail,
          acceptOfferta:data.data.acceptOfferta
        });
      }
      else{
        
          this.setState({
            errorInForm : true, 
            commonErrorMessage: data.message
          })
        
        //window.location = "https://t.me/TrastaTestingBot"
      }
    });
  }
 
  handleSubmit = event => {
    event.preventDefault()
    let errorinForm = false;
    if (this.state.trname == ''){
      errorinForm = true
      this.setState({
        errorInForm : true, 
        isErrorTrName: true,
      })
    }
    if (this.state.price == ''){
      errorinForm = true
      this.setState({
        errorInForm : true, 
        isErrorPrice: true,
      })
    }
    if (this.state.sellerEmail == ''){
      errorinForm = true
      this.setState({
        errorInForm : true, 
        isErrorSellerEmail: true,
      })
    }
    if (this.state.sellerName == ''){
      errorinForm = true
      this.setState({
        errorInForm : true, 
        isErrorSellerName: true,
      })
    }
    if (errorinForm){
      console.log('Error in Form'); 
      return false;
    }
    else{
      let requestUrl = `${process.env.GATSBY_TRUSTA_API_URL}/update-transaction`
      try {
        fetch(requestUrl, {
          method: 'POST', 
          body: JSON.stringify(this.state),
          mode: 'cors', // no-cors, *cors, same-origin
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }).then((response) => {
          return response.json();
        }).then((data) => {
          if (data.status === 'error'){
            if ( data.errors.acceptOfferta ) {
              this.setState({
                errorInForm : true, 
                isErrorAcceptOfferta: true,
              })
            }
            if ( data.errors.email ) {
              this.setState({
                errorInForm : true, 
                isErrorSellerEmail: true,
              })
            }
            if ( data.errors.price ) {
              this.setState({
                errorInForm : true, 
                isErrorPrice: true,
              })
            }
            if ( data.errors.sellerName ) {
              this.setState({
                errorInForm : true, 
                isErrorSellerName: true,
              })
            }
            if (data.message){
              this.setState({
                errorInForm : true, 
                commonErrorMessage: data.message
              })
            }
            
          }
          else{
            window.location = `https://t.me/${process.env.GATSBY_TELEGRAM_BOT}`
          }
        });
      } catch (error) {
        console.error('Ошибка:', error);
        this.setState({
          errorInForm : true, 
          commonErrorMessage: 'Внутрішня помилка',
        })
      }
    }
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    
    this.setState({
      [name]: value,   
    })
  }

  handleInputChangeTrName = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    let errorInForm = false
    let isErrorTrName = false
    if ( this.state.isErrorTrName || this.state.isErrorPrice || this.state.isErrorSellerEmail || this.state.isErrorAcceptOfferta || this.state.isErrorSellerName){
      errorInForm = true
    }
    if (value === ''){
      isErrorTrName = true
    }
    this.setState({
      [name]: value, 
      errorInForm : errorInForm ,
      isErrorTrName: isErrorTrName,
      commonErrorMessage: null
    })
  }

  handleInputChangePrice = event => {
    const target = event.target
    const value = target.value.replace(/\,/g,'.')
    const name = target.name
    let errorInForm = false
    let isErrorPrice = false;
    if (isNaN(value)){
      isErrorPrice = true
    }

    if ( this.state.isErrorTrName || isErrorPrice || this.state.isErrorSellerEmail || this.state.isErrorAcceptOfferta || this.state.isErrorSellerName){
      errorInForm = true
    }
    this.setState({
      [name]: value, 
      errorInForm : errorInForm, 
      isErrorPrice : isErrorPrice,
      commonErrorMessage: null
    })
  }
  handleInputChangeSellerEmail = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    let errorInForm = false
    let isErrorEmail = false;

    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)){
      isErrorEmail = true
    }

    if ( this.state.isErrorTrName || this.state.isErrorPrice || isErrorEmail || this.state.isErrorAcceptOfferta || this.state.isErrorSellerName){
      errorInForm = true
    }
    this.setState({
      [name]: value, 
      errorInForm : errorInForm, 
      isErrorSellerEmail : isErrorEmail,
      commonErrorMessage: null
    })
  }

  handleInputChangeSellerName = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    let errorInForm = false
    let isErrorSellerName = false;

    if (value == ''){
      isErrorSellerName = true
    }

    if ( this.state.isErrorTrName || this.state.isErrorPrice || this.state.isErrorEmail || this.state.isErrorAcceptOfferta || isErrorSellerName ){
      errorInForm = true
    }
    this.setState({
      [name]: value, 
      errorInForm : errorInForm, 
      isErrorSellerName : isErrorSellerName,
      commonErrorMessage: null
    })
  }

  handleCheckBox = event => {
    const target = event.target
    const value = target.name === 'acceptOfferta' ? target.checked : target.value;
    const name = target.name;
    let errorInForm = false;
    let isErrorAcceptOfferta = false;
    if (!target.checked){
      isErrorAcceptOfferta = true;
    }
    if ( this.state.isErrorTrName || this.state.isErrorPrice || this.state.isErrorSellerEmail || isErrorAcceptOfferta){
      errorInForm = true
    }

    this.setState({
      [name]: value,
      isErrorAcceptOfferta: isErrorAcceptOfferta,
      errorInForm: errorInForm,
      commonErrorMessage: null
    });
  }

  render() {
    return (
      <div style={{marginTop:"2em"}}>
        <h2> Редагування завдатку </h2>
         <form onSubmit={this.handleSubmit}>
        <label className={ this.state.isErrorTrName ? `error` : null }>
        *За що вноситься завдаток:
          <input
            className={ this.state.isErrorTrName ? `error` : null }
            type="text"
            name="trname"
            value={this.state.trname}
            onChange={this.handleInputChangeTrName}
            placeholder="Опис послуги/товару"
          />
          { this.state.isErrorTrName ? <small> Tets </small> : null }         
        </label>

        <label className={ this.state.isErrorPrice ? `error` : null } >
          *Сума завдатку (від 10 до 29999 грн.):
          <input
            type="text"
            className={ this.state.isErrorPrice ? `error` : null }
            name="price"
            value={this.state.price}
            onChange={this.handleInputChangePrice}
            placeholder="100.00"
          />
          { this.state.isErrorPrice ? <small> Введіть корректне значення, мінімальна сума завдатку 10 грн </small> : null } 
        </label>
        <label className={ this.state.isErrorSellerEmail ? `error` : null } >
          *Ваш Email:
          <input
            type="email"
            className={ this.state.isErrorSellerEmail ? `error` : null }
            name="sellerEmail"
            value={this.state.sellerEmail}
            onChange={this.handleInputChangeSellerEmail}
            placeholder="seller@trusta.ua"
          />
          { this.state.isErrorSellerEmail ? <small> Введіть корректне значення </small> : null }
        </label>
        <label className={ this.state.isErrorSellerName ? `error` : null }>
          * Ваша назва або ім&#39;я:
          <input
            type="text"
            className={ this.state.isErrorSellerName ? `error` : null }
            name="sellerName"
            value={this.state.sellerName}
            onChange={this.handleInputChangeSellerName}
            placeholder=""
          />
          { this.state.isErrorSellerName ? <small> Введіть корректне значення </small> : null }
        </label>
        <p>
        <label className={ this.state.isErrorAcceptOfferta ? `error` : null }>
          Я погоджуюсь з <a style={{color:"cornflowerblue"}} href="/offerta" target="_blank" >умовами</a> сервісу
          <input
            type="checkbox"
            name="acceptOfferta"
            className={ this.state.isErrorAcceptOfferta ? `error` : null }
            checked={this.state.acceptOfferta}
            onChange={this.handleCheckBox}
            value={this.state.acceptOfferta}
          />
          { this.state.isErrorAcceptOfferta ? <p><small> Ви маєте прийняти умови використання </small></p> : null  }
        </label>
        </p>
        
        { this.state.commonErrorMessage ? <p className="error-box"> {this.state.commonErrorMessage} </p> : null }
        <button type="submit" style={{width:"100%"}} disabled={this.state.errorInForm} >Зберегти</button>
      </form>
      </div>
    )
  }
}
export default TransactionEditForm